
<template>
  <div class="pageMain">
    <search-head @reset="headReset" @search="headSearch">
      <el-form :inline="true" :model="searchForm" >
        <el-form-item label="用户名称">
          <el-select size="small"  clearable v-model="searchForm.staffName" placeholder="请选择用户名称">
            <el-option v-for="item in staffList" :key="item.staffId" :label="item.staffName" :value="item.staffName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色名称">
          <el-select size="small" clearable v-model="searchForm.roleIds" placeholder="请选择角色名称">
            <el-option v-for="item in roleList" :key="item.roleId" :label="item.roleName" :value="item.roleId"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </search-head>
    <custom-tables :data="tableData" :total="total"
                   isdel="true"
                   noimport="true"
                   noexport="true"
                   title="用户角色"
                   @selectionChange="selectionChange"
                   @handleCurrentChange="handleCurrentChange"
                   @handleSizeChange="handleSizeChange"
                   @tableDel="tableDel" @tableAdd="tableAdd">
      <el-table-column
          type="selection"
          align="center"
          width="55">
      </el-table-column>
      <el-table-column
          prop="staffName"
          align="center"
          label="用户姓名"
          width="180">
      </el-table-column>
      <el-table-column
          prop="roleName"
          label="角色名称"
          align="center"
          width="180">
      </el-table-column>
      <el-table-column
          prop="staffRoleNote"
          align="center"
          label="备注信息">
      </el-table-column>
      <el-table-column label="操作" align="center"  width="100">
        <template slot-scope="scope">
          <span class="el-icon-edit btn-icon" @click="editItem(scope.row)"></span>
          <span class="el-icon-delete btn-icon" @click="delItem(scope.row)"></span>
        </template>
      </el-table-column>
    </custom-tables>
    <custom-dialog :visible.sync="showDialog" :title="dialogTitle" width="500px" @close="colseDialog" @submit="editsubmit">
      <el-form :model="editForm" label-width="80px" class="edit-form" :rules="rules">
        <el-form-item label="用户姓名" prop="staffId">
          <el-select class="block-select" size="small" v-model="editForm.staffId" placeholder="请选择用户">
            <el-option v-for="item in staffList" :key="item.staffId" :label="item.staffName" :value="item.staffId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色" prop="roleIds">
          <el-select class="block-select" size="small" multiple  v-model="editForm.roleIds" placeholder="请选择角色">
            <el-option v-for="item in roleList" :key="item.roleId" :label="item.roleName" :value="item.roleId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="staffRoleNote">
          <el-input size="small" v-model="editForm.staffRoleNote" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
    </custom-dialog>
  </div>
</template>

<script>
import CustomTables from "../../../components/common/CustomTables";
import SearchHead from "../../../components/common/SearchHead";
import CustomDialog from "../../../components/common/CustomDialog";
import rights from "../../../api/modules/rights";
import common from "../../../api/modules/common";

export default {
  name: "staffRole",
  components: {CustomDialog, SearchHead, CustomTables},
  data(){
    return {
      dialogTitle:'新增公司',
      showDialog: false,
      total:0,
      tableData:[],
      searchForm:{
        currPage: 1,
        pageSize:10
      },
      editForm:{
        idCardNo:'',
        orgaId:'', //机构id，有企业选企业，有公司选公司
        projId:'',
        staffId:'',
        staffMobile:'',
        staffName:'',
        staffPic:'',
        staffSex:'',
        companyParentId:'',
        companyId:''
      },
      rules:{
        staffName: [
          { required: true, message: '请输入人员姓名', trigger: 'blur' },
        ],
      },
      roleList: [],
      staffRoleIdList:[],
      staffList:[]
    }
  },
  mounted() {
    this.getInfo()
    this.getBasicInfo()
  },
  methods:{
    getInfo(){
      rights.getStaffRoleListByPage(this.searchForm).then(res =>{
        this.tableData = res.data.list
        this.total = res.data.total
      })
    },
    getBasicInfo(){
      // 获取角色列表
      rights.getRoleList().then(res =>{
        this.roleList = res.data
      })
      // 获取管理人员
      common.selectStaffList().then(res =>{
        this.staffList = res.data
      })

    },
    handleCurrentChange(pageIndex){
      this.searchForm.currPage = pageIndex
      this.getInfo()
    },
    handleSizeChange(pageSize){
      this.searchForm.currPage = 1
      this.searchForm.pageSize = pageSize
      this.getInfo()
    },
    headReset(){
      this.searchForm = {
        currPage:1,
        pageSize:10
      }
      this.getInfo()
    },
    headSearch(){
      this.searchForm.currPage = 1
      this.searchForm.pageSize = 10
      this.getInfo()
    },
    tableDel(){
      this.$confirm(`此操作将永久删除选中的用户角色, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        rights.deleteStaffrole({staffRoleIds:this.staffRoleIdList}).then(res =>{
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.getInfo()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    tableAdd(){
      this.dialogTitle = '新增用户角色'
      this.showDialog = true
    },
    editItem(item){
      this.editForm = item
      this.dialogTitle = '新增用户角色'
      this.showDialog = true
    },
    selectionChange(val){
      console.log(val)
      this.staffRoleIdList = []
      val.forEach(item =>{
        this.staffRoleIdList.push(item.staffRoleId)
      })
    },
    delItem(item){
      console.log(item)
      this.$confirm(`此操作将永久删除该用户角色, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        rights.deleteStaffrole({staffRoleIds:[item.staffRoleId]}).then(res =>{
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.getInfo()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    editsubmit(){
      // 编辑提交
      rights.addStaffrole(this.editForm).then(res =>{
        this.$message({
          message: '录入成功',
          type: 'success'
        });
        this.getInfo()
        this.showDialog = false
      })
    },
    colseDialog(){
      // 关闭编辑
      this.showDialog = false
    }
  }
}
</script>

<style scoped lang="less">
.edit-form{
  padding-right: 40px;
}


</style>